<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-9">
            <b-form-select
              v-model="filterTransactionType"
              :options="transactionTypes"
              size="sm"
            >
              <template v-slot:first>
                <option value="" disabled>
                  {{ $t("TRANSACTIONS.SELECT_TRANSACTION_TYPE") }}
                </option>
              </template>
            </b-form-select>

            <vue-typeahead-bootstrap
              id="filter1"
              v-model="filterUser"
              :minMatchingChars="1"
              trim
              size="sm"
              @input="
                requestSuggestionVariants(
                  'accountSuggestionProcessor',
                  filterUser
                )
              "
              :data="suggestedVariants"
              :serializer="(s) => s.name"
              @hit="onItemSelected"
              :placeholder="$t('TRANSACTIONS.TYPE_USER_NAME')"
              style="min-width: 30%"
            >
            </vue-typeahead-bootstrap>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Search"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";

export default {
  name: "properties",
  mixins: [SuggestionProcessorMixin],
  components: {},
  data() {
    return {
      fields: [
        {
          key: "id",
          formatter: Common.idObjectFormatter,
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "created",
          formatter: Common.idObjectFormatter,
          label: this.$t("COMMON.CREATED"),
          sortingKey: "el.created",
          sortable: true,
        },
        {
          key: "amount",
          label: this.$t("TRANSACTIONS.AMOUNT"),
          sortingKey: "el.amount",
          sortable: true,
        },
        {
          key: "balanceBefore",
          label: this.$t("TRANSACTIONS.BALANCE_BEFORE"),
          sortingKey: "el.balanceBefore",
          sortable: true,
        },
        {
          key: "balanceAfter",
          label: this.$t("TRANSACTIONS.BALANCE_AFTER"),
          sortingKey: "el.balanceAfter",
          sortable: true,
        },
        {
          key: "transactionTypeId",
          label: this.$t("TRANSACTIONS.TRANSACTION_TYPE"),
          formatter: this.typeFormatter,
          sortingKey: "el.transactionType.id",
          sortable: true,
        },
        {
          key: "accountExternalIdentifier",
          label: this.$t("TRANSACTIONS.ACCOUNT_EXTERNAL_ID"),
          sortingKey: "el.account.id",
          sortable: true,
        },
      ],

      filterTransactionType: "",
      transactionTypeRequest: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.account.model.TransactionType",
        requestType: "GET",
        requestParams: {},
      },
      transactionTypes: [],

      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/platform/api/transaction",
        requestType: "GET",
        requestParams: { enrich: true },
      },

      currentPage: 1, // page numbers indexed from 1
      perPage: 10,

      showTableOverlay: true,

      errors: [],
      calculate: false,
      selectedItem: null,
      filterUser: "",
      selectedAccountId: null,
      typeMap: {},
    };
  },

  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },

  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.TRANSACTIONS") },
    ]);
    this.$store.dispatch(SET_ACTIONS, [
      // { label: this.$t("ACTIONS.CREATE_NEW"), action: this.onCreateNewAction, icon: "fas fa-plus"  },
      // { label: this.$t("ACTIONS.EDIT"), action: this.onEditAction, disabled: function() { return that.selectedItem === null; }, icon: "fas fa-edit"  },
      // { label: this.$t("ACTIONS.DELETE"), action: this.onDeleteAction, disabled: function() { return that.selectedItem === null; }, icon: "fas fa-trash-alt" },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
    ]);
  },

  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    this.loadTransactionTypes();
    this.loadList();
  },

  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    loadTransactionTypes: function () {
      this.$store
        .dispatch(API_REQUEST, this.transactionTypeRequest)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            this.typeMap[response[i].id] = response[i];
          }
          for (const value of response) {
            this.transactionTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
          }
        })
        .catch(this.onError);
    },

    onItemSelected: function (value) {
      this.selectedAccountId = value.description;
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];

        this.$bus.$emit("dropdown_actions:animate");
      } else {
        this.selectedItem = null;
      }
    },

    onCreateNewAction: function () {
      this.$router.push({ name: "transaction" });
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction: function () {
      this.$router.push({
        name: "account",
        params: { id: this.selectedItem.id },
      });
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete: function () {
      const del = {
        resource:
          "/platform/api/transaction/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;

      this.loadList();
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },

    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;

      this.getList.requestParams["transactionTypeId"] =
        this.filterTransactionType.length > 0
          ? this.filterTransactionType
          : null;
      this.getList.requestParams["accountId"] =
        this.selectedAccountId != null ? this.selectedAccountId : null;
      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["transactionTypeId"];
      delete this.getList.requestParams["accountId"];
      this.filterTransactionType = "";
      this.selectedAccountId = null;
      this.filterUser = null;
      this.suggestedVariants = [];
      this.loadList();
    },
    typeFormatter: function (value) {
      if (value && value in this.typeMap)
        return this.typeMap[value].nameLocalized;

      return "";
    },
  },
};
</script>
